import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    delaySeconds: Number,
    frameId: String
  }

  connect() {
    const self = this

    if (this.delaySecondsValue) {
      this.timer = setTimeout(
        () => self.redirect(),
        this.delaySecondsValue * 1_000
      )
    }
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  redirect() {
    const url = this.urlValue
    const frameId = this.frameIdValue

    if (url && frameId) {
      Turbo.visit(url, {
        frame: frameId
      })
    }
  }
}
